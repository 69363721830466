import React from "react"
import styled from "styled-components"
import { colours } from "../../style/colours"

const Container = styled.article`
  background: white;
  padding: 20px;
  border: 1px solid ${colours.maroonDark};
  border-radius: 10px;
  margin-bottom: 50px;
`
const BlogTitle = styled.h2`
  color: ${colours.greenDark};
`
const Excerpt = styled.p`
  color: ${colours.greenLight};
`
const Button = styled.button`
  background: none;
  color: ${colours.maroonLight};
  border: none;
  padding: 0;
  cursor: pointer;
`

const BlogItem = ({ title, excerpt, link }) => (
  <Container>
    <BlogTitle>Controlling PCOS</BlogTitle>
    <Excerpt>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
      amet, consectetur adipiscing elit, sed do eiusmod.
    </Excerpt>
    <Button>Read More</Button>
  </Container>
)

export default BlogItem
