import React, { Component } from 'react';
import styled from 'styled-components'
import Layout from '../components/layout';
import BlogItem from '../components/blog/blog-item';
import { colours } from '../style/colours';

const BlogContainer = styled.div`
  background-image: url(${require('../images/wood.jpg')});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
`;
const InnerContainer = styled.div`
  max-width: 1200px;
  padding: 50px 30px;
  margin: 0 auto;

`;
const Title = styled.h1`
	color: ${colours.maroonDark};
`;

class BlogPage extends Component {
	state = {}
	render() {
		return (
			<Layout>
				<BlogContainer>
					<InnerContainer>
						<Title>Blog</Title>
						<BlogItem />
						<BlogItem />
						<BlogItem />
						<BlogItem />
					</InnerContainer>
				</BlogContainer>
			</Layout>
		);
	}
}

export default BlogPage;